import {
  PriceDomain,
  CatalogOfferingDto,
  OfferingType,
  WeekDay,
  PriceDto,
} from '@wix/bookings-uou-domain';

export class OfferingDomain {
  private constructor(protected offeringDto: CatalogOfferingDto) {}

  static fromDto(offeringDto: CatalogOfferingDto) {
    return offeringDto ? new OfferingDomain(offeringDto) : null;
  }

  get name() {
    return this.offeringDto.info.name;
  }

  set name(newName: string) {
    this.offeringDto.info.name = newName;
  }

  get type() {
    return this.offeringDto.type;
  }

  get categoryId() {
    return this.offeringDto.categoryId;
  }

  get fullUrl() {
    return this.offeringDto.fullUrl;
  }

  get isPendingApprovalFlow() {
    return this.offeringDto.schedulePolicy.isPendingApprovalFlow;
  }

  get priceText() {
    const priceDto: PriceDto = {
      ...this.offeringDto.payment,
      displayTextForPlan: this.offeringDto.pricingPlanInfo
        ? this.offeringDto.pricingPlanInfo.displayText
        : '',
    };
    return new PriceDomain(priceDto, this.offeringDto.offeredAs).text;
  }

  get durationText() {
    return this.offeringDto.scheduleHeader.durationText;
  }

  get pricingPlanInfo() {
    return this.offeringDto.pricingPlanInfo;
  }

  get scheduleHeader() {
    return this.offeringDto.scheduleHeader;
  }

  daysTextByFormat(formatter) {
    if (this.type === OfferingType.GROUP) {
      const daysOrder =
        formatter('first-day-of-week') === formatter('week-day.sun')
          ? [
              WeekDay.SUNDAY,
              WeekDay.MONDAY,
              WeekDay.TUESDAY,
              WeekDay.WEDNESDAY,
              WeekDay.THURSDAY,
              WeekDay.FRIDAY,
              WeekDay.SATURDAY,
            ]
          : [
              WeekDay.MONDAY,
              WeekDay.TUESDAY,
              WeekDay.WEDNESDAY,
              WeekDay.THURSDAY,
              WeekDay.FRIDAY,
              WeekDay.SATURDAY,
              WeekDay.SUNDAY,
            ];
      const offeringDays = this.offeringDto.scheduleHeader.days;
      if (offeringDays.length === 7) {
        return formatter('working-hours.all-week');
      }
      const weekDayKeyPrefix: string =
        offeringDays.length <= 2 ? 'week-day.' : 'short-week-day.';
      return daysOrder
        .filter((day) => offeringDays.indexOf(day) > -1)
        .map((day) => formatter(weekDayKeyPrefix + day))
        .join(', ');
    }
  }

  errorMessageTranslation(formatter) {
    return formatter('tile-service.premium-not-enabled.message');
  }

  get image() {
    const image =
      this.offeringDto.info.images && this.offeringDto.info.images[0];
    return image ? image : null;
  }

  get displayOnlyNoBookFlow() {
    return this.offeringDto.schedulePolicy.displayOnlyNoBookFlow;
  }

  get description() {
    return this.offeringDto.info.description;
  }

  get tagLine() {
    return this.offeringDto.info.tagLine;
  }

  get payment() {
    return this.offeringDto.payment;
  }

  set currency(newCurrency) {
    this.offeringDto.payment.currency = newCurrency;
  }

  get id() {
    return this.offeringDto.id;
  }

  get dummy() {
    return this.offeringDto.dummy;
  }
}
