import * as React from 'react';

export interface RunningEnvironmentContext {
  isMobile: boolean;
  isEditorMode: boolean;
  isPreviewMode: boolean;
  isSSR: boolean;
  isRTL: boolean;
  isDummyMode: boolean;
  scale: number;
  dimensions: {
    width: number;
    height: number;
  };
}

export interface RunningEnvironmentProps {
  runningEnvironment?: RunningEnvironmentContext;
}

const runningEnvironmentContext = React.createContext<
  RunningEnvironmentContext
>({
  isMobile: false,
  isEditorMode: false,
  isPreviewMode: false,
  isSSR: false,
  isRTL: false,
  isDummyMode: false,
  scale: 1,
  dimensions: {
    height: 0,
    width: 0,
  },
});

export const RunningEnvironmentContextProvider =
  runningEnvironmentContext.Provider;

export const RunningEnvironmentContextConsumer =
  runningEnvironmentContext.Consumer;

export const withRunningEnvironmentContext = <TProps extends object>(
  WrappedComponent: React.ComponentType<TProps>,
) =>
  class withRunningEnvironmentContextComponent extends React.Component<
    TProps & RunningEnvironmentProps
  > {
    render() {
      return (
        <RunningEnvironmentContextConsumer>
          {(context: RunningEnvironmentContext) => {
            const runningEnvironmentProps: RunningEnvironmentProps = {
              runningEnvironment: context,
            };
            return (
              <WrappedComponent {...this.props} {...runningEnvironmentProps} />
            );
          }}
        </RunningEnvironmentContextConsumer>
      );
    }
  };

export const RunningEnvironmentWrapper = ({ runningEnvironment }) => {
  return (Component) => {
    return (
      <RunningEnvironmentContextProvider value={runningEnvironment}>
        {Component}
      </RunningEnvironmentContextProvider>
    );
  };
};
