import { FormFactor } from '../WidgetApp/constants/environment.const';
import * as _ from 'lodash';

export const getPresetId = (): any =>
  new Promise((resolve) => {
    window.Wix.Data.Public.get(
      'presetId',
      { scope: 'COMPONENT' },
      (data) => resolve(data),
      () => resolve(),
    );
  });

export const getSiteTextPresets = () =>
  new Promise((resolve) => {
    window.Wix.Styles.getSiteTextPresets(resolve, resolve);
  });

export const getSiteColors = () =>
  new Promise((resolve) => {
    window.Wix.Styles.getSiteColors(resolve, resolve);
  });

export const getStyleParams = () =>
  new Promise((resolve) => {
    window.Wix.Styles.getStyleParams(resolve, resolve);
  });

export const getCurrentStyles = () =>
  Promise.all([getSiteColors(), getSiteTextPresets(), getStyleParams()]);

export const isMobileFromFormFactor = (props) =>
  _.get(props, 'host.formFactor', FormFactor.DESKTOP) === FormFactor.MOBILE;

export function cleanNulls(object: any) {
  Object.keys(object).forEach(
    // tslint:disable-next-line:no-dynamic-delete
    (key) => object[key] === null && delete object[key],
  );
  return object;
}
